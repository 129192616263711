import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, WritableSignal, signal } from '@angular/core';
import { Router } from '@angular/router';
import { CloudinaryService } from '../../../core/services/cloudinary.service';
import { DeferLoadDirective } from '../../../shared/directives/defer-load.directive';
import { SafePipe } from '../../../shared/components/pipes/safe-url.pipe';

@Component({
  selector: 'service-cards',
  standalone: true,
  imports: [CommonModule, DeferLoadDirective, SafePipe],
  templateUrl: './home-service-cards.component.html',
  styleUrl: './home-service-cards.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeServiceCardsComponent {
  constructor(
    public cloudinary: CloudinaryService,
    public router: Router,
  ) {
    this._setData();
  }
  @Input() flip = false;
  intersectData: WritableSignal<any> = signal({});

  data = [
    {
      title: 'restorative',
      image: 'skinLogic/ZakfbfXcvG.png',
      content:
        'Revitalize your beauty with our <b>restorative</b> procedures, tailored for a radiant, rejuvenated you. Trust our experts for transformative results.',
      services: [
        'Microneedling RF',
        'Focus Array or Elite+',
        'Coolsculpting - Fat Reduction',
        'Fibroblast - Plasma Pen',
        'FUE Hair Transplants',
      ],
    },
    {
      title: 'corrective',
      image: 'skinLogic/VmUnoRGpWH.png',
      content:
        'Elevate your beauty with <b>corrective</b> procedures, expertly designed for a confident, renewed you. Trust us for exceptional results.',
      services: [
        'Tattoo Removal - PicoSure Laser',
        'Superficial Vein Treatments',
        'Laser Hair Reduction',
        'Skin Tag Removal',
      ],
    },
    {
      title: 'nourishing',
      image: 'skinLogic/jjFeAsvNzu.png',
      content:
        'Revitalize with our <b>nourishing</b> procedures for a radiant you. Trust us for exceptional results and a rejuvenated appearance.',
      services: [
        'Dermaplaning - Exfoliating',
        'Hydrodermabrasion - Deep pore cleaning + serum infusion',
        'Hydrating Facials',
        'Pelleve/Tempsure - Collagen Boosting Facial',
      ],
    },
  ];

  _setData() {
    const data: any = {};
    for (let index = 0; index < this.data.length; index++) {
      data[index] = false;
    }
    this.intersectData.set(data);
  }

  onIntersect(index: number) {
    const value = this.intersectData();
    value[index] = true;
    this.intersectData.set(value);
  }

  openServices(key: string, index: number) {
    this.router.navigate(['services'], { replaceUrl: false, queryParams: { type: key, index: index } });
  }
}
