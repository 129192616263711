<div class="flex flex-col service sl-box">
  @for (item of data; track $index) {
    <div
      [ngClass]="{ 'md:flex-row-reverse': $index % 2 == 1, 'md:-mt-[7%]': $index > 0 }"
      class="flex flex-col md:flex-row items-center justify-between"
    >
      <div
        class="group w-[95%] md:w-[300px] lg:w-[350px] xl:w-[400px] 2xl:w-[500px] flex-shrink-0 relative"
        deferLoad
        (onIntersect)="onIntersect($index)"
        [threshold]="0.5"
        [ngClass]="[
          intersectData()[$index] ? 'block' : 'invisible',
          intersectData()[$index] && $index % 2 == 0 ? 'scale-up-center' : '',
          intersectData()[$index] && $index % 2 == 1 ? 'scale-up-center' : ''
        ]"
      >
        <div class="flex flex-col shadow-lg front-card aspect-[0.7765]">
          <img
            fetchpriority="low"
            [alt]="item.title"
            [src]="cloudinary.getUrl({ id: item.image, width: 400 })"
            class="h-full w-full object-cover object-center bg-gray-100"
          />
          <h3
            [ngClass]="$index % 2 == 0 ? 'label-top' : 'label-bottom'"
            class="text-white marcellus text-[60px] 2xl:text-[80px] font-medium absolute group-hover:hidden"
          >
            {{ item.title }}
          </h3>
        </div>

        <div class="absolute top-0 left-0 bg-blur h-full w-full hidden group-hover:block">
          <div
            class="flex flex-col items-start justify-center gap-12 px-12 absolute top-0 h-full w-full overflow-hidden"
          >
            @for (service of item.services; track $index) {
              <div
                class="flex flex-row gap-10 items-center w-full scale-up-center cursor-pointer hover:underline text-white"
                (click)="openServices(item.title, $index)"
              >
                <span class="h-[8px] lg:h-[12px] aspect-1 rounded-full bg-white"></span>
                <h1 class="text-white marcellus text-20 lg:text-24 text-start">{{ service }}</h1>
              </div>
            }
          </div>
        </div>
      </div>

      <div class="hidden lg:inline-flex w-full mx-20 items-center">
        <span class="h-[7px] w-[7px] rounded-full bg-black flex-shrink-0"></span>
        <span class="h-[1px] flex-grow bg-[#333]"></span>
      </div>

      <div
        [ngClass]="intersectData()[$index] ? 'scale-up-center' : 'invisible'"
        class="md:max-w-[300px] lg:max-w-[400px] text-gunmetal leading-[32px] 2xl:leading-[40px] text-18 2xl:text-20 flex-shrink-0 py-16 md:py-0 text-center"
        [innerHTML]="item.content | safe: 'html'"
      ></div>
    </div>
  }
</div>
